import { render } from 'react-dom';
import * as React from "react";
import SearchContainer from "./Search/SearchContainer";
export default function SearchRoot(props) {
    const { locale, domain } = props;
    return (React.createElement("div", null,
        React.createElement(SearchContainer, { domain: domain, locale: locale })));
}
if (document.getElementById("search-modal")) {
    let elem = document.getElementById("search-modal");
    if (elem) {
        var domain = elem.getAttribute('domain');
        var locale = elem.getAttribute('locale');
        render(React.createElement(SearchRoot, { domain: domain, locale: locale }), elem);
    }
}
