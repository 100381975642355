import * as React from "react";
import { useMemo } from "react";
export default function SearchResult(props) {
    var striptags = require('striptags');
    const { hits } = props;
    const hasResults = useMemo(() => {
        return hits.length > 0;
    }, [hits]);
    return (React.createElement("div", { className: "search-result" }, hits.map((item) => {
        return (React.createElement("a", { href: item.url, className: "hit", key: item.id },
            item.image &&
                React.createElement("img", { src: item.image, alt: item.title }),
            React.createElement("div", { className: "title" }, item.title)));
    })));
}
